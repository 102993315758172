@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/*

@layer components {
  .btn-primary {
    @apply py-2 px-4 bg-blue-200;
  }
}

*/


@layer utilities {
  .tw-scrollbar-gutter-stable {
    scrollbar-gutter: stable;
  }
}

@layer components {
  .richtext {
    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.875rem;
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: 0.875rem;
    }

    h3 {
      font-size: 1.125rem;
      margin-bottom: 0.875rem;
    }

    ul {
      list-style-type: disc;
      padding-left: 1.25rem;
    }

    ol {
      list-style-type: decimal;
      padding-left: 1.25rem;
    }

    em {
      font-style: italic;
    }

    a {
      color: #0085ff;
      text-decoration-line: underline;
    }

    blockquote {
      border-color: #b6b6b6;
      border-left: 4px;
      border-style: solid;
      color: #363636;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 4px;
      padding: 0 12px;
    }

    p {
      margin: 1rem 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.is-editor-empty:first-child::before {
        color: #b6b6b6;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
      }
    }
  }

  .email_blockquote {
    blockquote {
      background-color: #f2f2f2 !important;
      border-left: 2px solid #b6b6b6 !important;
      padding: 0.5rem 0.75rem !important;

      &:first-of-type {
        margin-top: 0.25rem !important;
      }
    }
  }
}
